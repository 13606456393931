.Description{
  @media screen and (min-width:1024px) {
    position: absolute;
    color:white;
    left: 19vw;
    top: 22vw;
    .Title{
      font-size: 42px;
      font-weight: bold;
    }
    .SubTitle{
      width:528px;
      height:61px;
      font-size: 14px;
    }
    .DetailBtn{
      text-decoration: none;
      display: block;
      width:142px;
      height:52px;
      border-radius: 26px;
      line-height: 52px;
      background-color: #f18101;
      color:white;
      margin-top: 40px;
      font-size: 18px;
      text-align: center;
      cursor: pointer;
      &:hover{
        background-color: #e07001;
      }
    }
  }
  @media screen and (max-width:1024px) {
    text-align: center;
    position: absolute;
    color:white;
    top: 110vw;
    .Title{
      font-size: 21px;
    }
    .SubTitle{
      width: 84%;
      font-size: 12px;
      margin: 0 auto;
      text-align: justify;
    }
    .DetailBtn{
      display: none;
    }
  }
}

.ImageWrap {
  position: relative;
  > img {
    display: block;
    width: 100%;
    height: 200px;
  }
  .OurGame {
    position: absolute;
    left: 50%;
    top: 50px;
    transform: translateX(-50%);
    color: #fff;
    .Title {
      font-size: 56px;
      text-align: center;
    }
    .SubTitle {
      font-size: 24px;
      text-align: center;
    }
  }
}
@media screen and (max-width: 768px) {
  .ImageWrap {
    display: none;
  }
}