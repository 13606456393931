.TitleWrap {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  font-size: 30px;
  .Title {
    line-height: 1;
  }
  .SubTitle {
    line-height: 1;
    color: #d7d7d7;
    margin-left: 18px;
  }
}

@media screen and (max-width: 956px) {
  .TitleWrap {
    display: block;
    text-align: center;
    margin-bottom: 2px;
    .Title {
      font-size: 20px;
    }
    .SubTitle {
      margin: 0;
      font-size: 18px;
    }
  }
}