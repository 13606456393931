.Bg{
  width: 100%;
  height: 100vh;
  .BgImg{
    position: absolute;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100vh;
  }
  .Slogan{
    position: absolute;
    top:0;
    bottom: 0;
    left:0;
    right:0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    .SloganChinese{
      margin-top: -90px;
      font-family: 'Slogan Font';
      letter-spacing: 10px;
      font-size: 80px;
    }
    .SloganEnglish{
      font-family: Arial, Helvetica, sans-serif;
      font-size: 40px;
      letter-spacing: 10px;
      font-style: italic;
    }
  }
}
