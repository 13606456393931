.PCFooterContainer {
  @media screen and (min-width:490px){
    font-size: 12px;
    color: #8b8b8b;
    background: #171717;
    padding: 40px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    .Split {
      display: flex;
      justify-content: center;
      .LogoWrap {
        margin-right: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        > img {
          width: 72px;
          height: 52px;
          display: block;
        }
      }
      .Right {
        color: #8b8b8b;
        a {
          color: inherit!important;
        }
        .Nav {
          > li {
            display: inline-block;
            margin-right: 4px;
            &:not(:first-child) {
              margin-left: 4px;
            }
          }
        }
        .RightItem {
          margin: 4px 0;
        }
      }
    }
    a{
      text-decoration: none;
      color: #8b8b8b;
      cursor: pointer;
    }
    .FooterItem {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 24px;
    }
    .Tips {
      margin-top: 16px;
      letter-spacing: 4px;
    }
  }
  @media screen and (max-width:490px){
    display: none;
  }
}
.MobileFooterContainer{
  @media screen and (min-width:490px){
    display: none;
  }
  @media screen and (max-width:490px){
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #8b8b8b;
    line-height: 24px;
    padding:0 30px;
    background: #171717;
    .Item{
      text-align: center;
      a{
        text-decoration: none;
        color: #8b8b8b;
        cursor: pointer;
      }
    }
  }
}