.ProductContainer {
  background: #f2f2f2;
  padding: 85px 0;
  display: flex;
  align-items: center;
  color: #181818;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    padding: 20px 0;
  }
  .CardWrap {
    display: flex;
    justify-content: flex-start;
    max-width: 1230px;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 1250px) {
    .CardWrap {
      max-width: 830px;
    }
  }
  @media screen and (max-width: 836px) {
    .CardWrap {
      max-width: 416px;
    }
  }
  .Item {
    width: 380px;
    cursor: pointer;
    margin: 0 15px 30px 15px;
    @media screen and (max-width:1024px) {
      width:300px;
      margin: 20px auto 0 auto;
    }
    .ImageWrap {
      width: 100%;
      height: auto;
      overflow: hidden;
      border-radius: 5px 5px 0 0;
      position: relative;
      &:not(.WithCoverImg):hover {
        .ProductImg {
          transform: scale(1.2);
        }
      }
      &.WithCoverImg:hover {
        .QRCode {
          top: 0;
        }
      }
      .ProductImg {
        width: 100%;
        height: 100%;
        display: block;
        transition: all .6s ease;
      }
      .QRCode {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: -100%;
        left: 0;
        opacity: .85;
        transition: all .2s ease-out;
      }
      .ImageCover {
        position: absolute;
        background: rgba(20, 20, 20, .6);
        width: 100%;
        line-height: 224px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        color: #fff;
        text-align: center;
        font-size: 42px;
      }
    }
    .Footer {
      width: 100%;
      height: 76px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px;
      cursor: pointer;
      .Left {
        width: 280px;
        .Title {
          font-size: 18px;
          color: #1d1d1d;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .Abstract {
          font-size: 14px;
          color: #949494;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .Right {
        .Icon {
          font-size: 30px;
          color: #8C8C8C;
        }
      }
    }
    &:hover {
      box-shadow: 0 2px 10px 0 #dee4e8;
      border-radius: 3px;
      .Icon {
        color: #f3a83b!important;
      }
    }
  }
}
