$PAD_WIDTH: 1024px;
$PHONE_WIDTH: 414px;

.BannerContainer{
  .SwiperContainer{
    --swiper-pagination-color:#727272;
    --swiper-navigation-color: #ffffff;
    overflow: hidden;
    @media screen and (max-width:$PAD_WIDTH) {
      --swiper-navigation-color: transparent;
    }
    :global(.swiper-pagination){
      @media screen and (max-width:$PAD_WIDTH) {
        display: none;
      }
    }
    :global(.swiper-pagination-bullet){
      width: 20px;
      height: 20px;
      background-color: transparent;
      border:2px solid #727272;
    }
    :global(.swiper-pagination-bullet-active){
      background-color: #727272;
    }
  }
  .Carousel{
    position: relative;
    picture{
      object-fit:contain;
    }
    .BackgroundImage{
      display: block;
      width: 100vw;
      height: 100%;
      height: auto;
    }
    .BackgroundVideo{
      width:100%;
      height:100%;
      object-fit:cover;
    }
    .InnerContent{
      position: absolute;
      display: flex;
      flex-direction: column;
      left:0;
      right:0;
      top:0;
      bottom: 0;
    }
  }
  .ArrowRow{
    position: absolute;
    top:50%;
    left:50%;
    width:80%;
    height:40px;
    margin-left: -40%;
    margin-top: -20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    svg{
      font-size: 26px;
      color:#727272;
    }
  }
  
  .BottomTabs{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #171717;
    height:50px;
    .BottomTab{
      height:50px;
      line-height: 50px;
      padding:0 50px;
      color:#8C8C8C;
      transition: color .1s ease-out;
      cursor: pointer;
    }
    .BottomTabSelected{
      color:#F18101;
    }
  }
  @media screen and (max-width: 768px) {
    .BottomTabs{
      display: none;
    }
  }
}

.SwiperContainerVertical{
  height:100vh
}