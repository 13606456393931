$PAD_WIDTH: 1024px;
$PHONE_WIDTH: 414px;
$HEADER_HEIGHT: 60px;
$HEADER_HEIGHT_MOBILE: 44px;
$MOBILE_MENU_HEIGHT: 60px;
$LOGO_WIDTH: 72px;
$LOGO_HEIGHT:52px;
$LOGO_WIDTH_MOBILE: 55px;
$LOGO_HEIGHT_MOBILE: 40px;
$COLUMN_WIDTH:150px;
$EXPAND_VIEW_HEIGHT:0px;

$HEADER_TITLE_FONT_SIZE: 16px;

$THEME_COLOR: rgb(202,8,19);
$ANIMATION_DURATION: 0.1s;


.HeaderContainer{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  transition: background-color $ANIMATION_DURATION ease-out;
  a{
    color: white;
    &:hover {
      color: $THEME_COLOR;
    }
  }
  @media (min-width:$PAD_WIDTH) {
    min-height: $HEADER_HEIGHT;
  }
  @media (max-width:$PAD_WIDTH) {
    background-color:rgba($color: #fff, $alpha: 0.6);
    min-height: $HEADER_HEIGHT_MOBILE;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
      font-size: 20px;
    }
  }
  @media (max-width:$PHONE_WIDTH) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .LogoMobile{
    @media (min-width:$PAD_WIDTH){
      display:none;
    }
    @media(max-width:$PAD_WIDTH){
      display: block;
      width:$LOGO_WIDTH_MOBILE;
      height: $LOGO_HEIGHT_MOBILE;
      img{
        width: $LOGO_WIDTH_MOBILE;
        height: $LOGO_HEIGHT_MOBILE;
      }
    }
    @media (max-width:$PHONE_WIDTH) {
      margin-left: 10px;
    }
  }
  .MenuBtn{
    @media (min-width:$PAD_WIDTH) {
      display: none;
    }
    @media (max-width:$PAD_WIDTH) {
      position: absolute;
      right: 10px;
      top:0;
      height:$HEADER_HEIGHT_MOBILE;
      width:60px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
  
  .Logo{
    @media (min-width:$PAD_WIDTH) {
      // width:$LOGO_WIDTH;
      width: 250px;
      height:$LOGO_HEIGHT;
      img{
        width:$LOGO_WIDTH;
        height:$LOGO_HEIGHT;
      }
    }
    @media (max-width:1120px){
      display:none
    }
  }
  .HeaderTop{
    @media (min-width:$PAD_WIDTH) {
      
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      min-height: $HEADER_HEIGHT;
      .Menu{
        display: flex;
        flex-direction: row;
        justify-content: center;
        // margin-left: 33.85%;
      }
      .MenuTitle{
        text-decoration: none;
        font-size: $HEADER_TITLE_FONT_SIZE;
        line-height: $HEADER_HEIGHT;
        width:$COLUMN_WIDTH;
        text-align: center;
        transition: background-color $ANIMATION_DURATION ease-out;
        &.Active {
          background-color: $THEME_COLOR;
          color:white;
        }
      }
    }
    @media (max-width:$PAD_WIDTH) {
      display: none;
    }
  }
  .ExpandView{
    @media (min-width:$PAD_WIDTH) {
      height: 0;
      overflow: hidden;
      width:100%;
      transition: height $ANIMATION_DURATION ease-out;
    }
    @media (max-width:$PAD_WIDTH) {
      display: none;
    }
    .MenuColumns{
      display: flex;
      flex-direction: row;
      justify-content: center;
      // align-items: center;
      // margin-left: 33.85%;
      .MenuColumn{
        display: flex;
        flex-direction: column;
        align-items: center;
        width:$COLUMN_WIDTH;
        margin: 20px 0;
        border-left: 2px solid #f4f4f4 !important;
        &:nth-child(-n+3) {
          border-left: none !important;
        }
        .MenuItem{
          text-decoration: none;
          margin-top: 10px;
          cursor: pointer;
          &:hover {
            color: $THEME_COLOR;
          }
        }
      }
    }
  }
  .ExpandViewExpanded{
    height: $EXPAND_VIEW_HEIGHT;
    border-bottom: 2px solid #f4f4f4;
  }
  .MobileMenu{
    width:100%;
    position: absolute;
    z-index: 20;
    height:0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    top:$HEADER_HEIGHT_MOBILE;
    background-color: rgba($color: #fff, $alpha: 0);
    transition: background-color $ANIMATION_DURATION ease;
    transition: height $ANIMATION_DURATION ease;
    .MobileMenuTitle{
      height:$MOBILE_MENU_HEIGHT;
      line-height: $MOBILE_MENU_HEIGHT;
      text-align: center;
      color:transparent;
      transition: color $ANIMATION_DURATION ease;
    }
    
  }
  .MobileMenuShow{
    height:100vh;
    background-color: rgba($color: #fff, $alpha: 0.9);
    .MobileMenuTitle{
      color:black;
      background-color: #f0f0f0;
      &:focus{
        background-color: white;
      }
    }
    .MobileActiveTitle{
      background-color: white;
    }
  }
}

.HeaderContainerWhite{
  background-color: #fff;
  a{
    text-shadow: none!important;
    color:black;
  }
}