$PAD_WIDTH: 1024px;
$PHONE_WIDTH: 414px;
$ANIMATION_DURATION: 0.3s;

.BottomNavigatorContainer{
  @media (min-width:$PAD_WIDTH) {
    display: none;
  }
  @media (max-width:$PAD_WIDTH) {
    border-top: 10px solid #f4f1ed;
  }
}
.PannelContainer{
  margin: 0 24px;
  border-bottom: 1px solid #f2f2f2;
  .Header{
    height:48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    .LeftArea{
      color:#646464;
      font-size: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .VerticalLine{
        width:4px;
        height: 16px;
        border-radius: 2px;
        background-color: #F18202;
        margin-right: 10px;
      }
    }
    .Arrow{
      svg{
        color:#b2b2b2;
      }
    }
  }
  .HeaderExpanded{
    box-shadow: 0px 1px 1px #f4f1ed;
  }
  .Content{
    height: 0px;
    transition: height $ANIMATION_DURATION ease;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .MenuItem{
      width: 120px;
      height: 30px;
      margin: 10px;
      background-color: #f4f1ed;
      border-radius: 4px;
      font-size: 13px;
      line-height: 30px;
      text-align: center;
    }
  }
  .ContentExpanded{
    height: 100px;
    @media (max-width:328px) {
      height: 200px;
    }
  }
}